import React from 'react';
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo"
import { graphql } from "gatsby"

export default ({data, location}) => (
  <Layout title="El Último Mono" location={location}>
    <Seo title="El Último Mono" description={data.site.siteMetadata.description} />
    <div className="ph6-l ph2-m ph2 sans-serif">
      <div className="cf pa4 db center tc black link">
        <iframe className="db center tc black link w-100 vh-50-l vh-25"src="https://www.youtube.com/embed/u52qYjhQ6_0" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Title</dt>
          <dd className="ml0">Los Asquerosos</dd>
          <dt className="clip">Artist</dt>
          <dd className="ml0 gray">El Último Mono</dd>
        </dl>
        <iframe className="db center tc black link w-100 vh-50-l vh-25"src="https://www.youtube.com/embed/Q63eTciUESU" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Title</dt>
          <dd className="ml0">El Muro</dd>
          <dt className="clip">Artist</dt>
          <dd className="ml0 gray">El Último Mono</dd>
        </dl>
        <iframe className="db center tc black link w-100 vh-50-l vh-25"src="https://www.youtube.com/embed/rUSFSndwY_A" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Title</dt>
          <dd className="ml0">La Hora del Lobo</dd>
          <dt className="clip">Artist</dt>
          <dd className="ml0 gray">El Último Mono</dd>
        </dl>
        <iframe className="db center tc black link w-100 vh-50-l vh-25"src="https://www.youtube.com/embed/ML6WWeAocQg" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Title</dt>
          <dd className="ml0">Nadie Va A Salvarnos</dd>
          <dt className="clip">Artist</dt>
          <dd className="ml0 gray">El Último Mono</dd>
        </dl>
        <iframe className="db center tc black link w-100 vh-50-l vh-25"src="https://www.youtube.com/embed/I53xagbOirE" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Title</dt>
          <dd className="ml0">Uróboros</dd>
          <dt className="clip">Artist</dt>
          <dd className="ml0 gray">El Último Mono</dd>
        </dl>
        <iframe className="db center tc black link w-100 vh-50-l vh-25"src="https://www.youtube.com/embed/LBf57avSHtQ" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Title</dt>
          <dd className="ml0">Que Baje el Telón</dd>
          <dt className="clip">Artist</dt>
          <dd className="ml0 gray">El Último Mono</dd>
        </dl>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
    query {
        site {
            siteMetadata {
                description
            }
        }
    }
`
